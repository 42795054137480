import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {OrderItem} from '@wix/cashier-express-checkout-widget/dist/src/types/OrderItem';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {BreakdownTypes} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {
  ShippingContactSelectedUpdate,
  ShippingContactRestricted,
} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import loadable from '@loadable/component';
import {withPropsChangeListeners, WithPropsChangeListenersProps} from '../../../providers/listenToPropsChanges';
import {OnPaymentAuthorizedResult} from '@wix/cashier-express-checkout-widget/src/types/ExternalContract';

const CashierExpressCheckoutWidgetLazy = loadable<WidgetProps>(() =>
  import(
    /* webpackChunkName: "CashierExpressCheckoutWidget" */ '@wix/cashier-express-checkout-widget/dist/src/lazy'
  ).then((module) => module.CashierExpressCheckoutWidget)
);

@withGlobalProps
@withPropsChangeListeners
export class CashierExpressButton extends React.Component<ProvidedGlobalProps & WithPropsChangeListenersProps> {
  public handleCashierPaymentSubmit = async (
    paymentInfo: PaymentAuthorizedArgs
  ): Promise<OnPaymentAuthorizedResult> => {
    if (paymentInfo.error) {
      throw paymentInfo.error;
    }

    this.props.globals.handleCashierPaymentSubmit(paymentInfo, this.props.globals.accessibilityEnabled);
    return this.props.waitForChange('handleCashierPaymentSubmitResult');
  };

  public handleExpressCashierShippingAddressChange = async (
    shippingAddress: ShippingContactRestricted
  ): Promise<ShippingContactSelectedUpdate> => {
    this.props.globals.fetchPaymentBreakdownForCashierAddress(shippingAddress);
    return this.props.waitForChange('fetchPaymentBreakdownForCashierAddressResult');
  };

  public onClick = async () => {
    // todo(eran): results can't be tested with current cashier testKit because this callback doesn't actually opens cashier modal in tests
    this.props.globals.handleCashierOnClick();
    if (await this.props.waitForChange('handleCashierOnClickResult')) {
      return Promise.resolve({canceled: false});
    } else {
      return Promise.resolve({canceled: true});
    }
  };

  private readonly getCartItemsForCashier = (): OrderItem[] => {
    const {selectedVariant, product} = this.props.globals;
    const price = (selectedVariant || product).price;

    const quantity = this.props.globals.userInputs.quantity[0];
    return [{id: product.id, name: product.name, price: price.toString(), quantity}];
  };

  private getPaymentBreakdownForCashier() {
    const {selectedVariant, product} = this.props.globals;
    const quantity = this.props.globals.userInputs.quantity[0];

    const price = ((selectedVariant || product).price * quantity).toString();

    return {
      paymentAmount: price,
      paymentBreakdown: {
        [BreakdownTypes.ItemsTotal]: price,
        [BreakdownTypes.Shipping]: '0',
        [BreakdownTypes.Tax]: '0',
        [BreakdownTypes.Discount]: '0',
      },
    };
  }

  public render() {
    const {
      meta,
      currency,
      locale,
      requestShipping,
      buttonStyle,
      domain,
    } = this.props.globals.cashierExpressCheckoutWidgetProps;
    const {paymentAmount, paymentBreakdown} = this.getPaymentBreakdownForCashier();

    return (
      <CashierExpressCheckoutWidgetLazy
        theme={this.props.globals.dynamicPaymentMethodsTheme}
        buttonStyle={buttonStyle}
        currency={currency}
        locale={locale}
        domain={domain}
        meta={meta}
        onClick={this.onClick}
        onPaymentAuthorized={this.handleCashierPaymentSubmit}
        onShippingContactSelected={this.handleExpressCashierShippingAddressChange}
        orderItems={this.getCartItemsForCashier()}
        paymentAmount={paymentAmount}
        paymentBreakdown={paymentBreakdown}
        paymentLabel="forApplePay"
        requestShipping={requestShipping}
      />
    );
  }
}
